import React from 'react'
import Error from 'next/error'

import { getStaticPage, queries } from '@data'

import Layout from '@components/layout'
import { Module } from '@components/modules'
import { buildSrc } from '@lib/helpers'

const Home = ({ data }) => {
  const { site, page } = data

  if (!page) {
    return (
      <Error
        title={`"Home Page" is not set in Sanity, or the page data is missing`}
        statusCode="Data Error"
      />
    )
  }

  return (
    <Layout site={site} page={page} schema={getPageSchema(site)}>
      {page.modules?.map((module, key) => {
        // Need a way to give the first spotlight image priority
        const { _type } = module
        const isSpotlight = _type === 'spotlight'
        const isFirst = key === 0
        return (
          <Module
            key={key}
            module={module}
            imagePriority={isSpotlight && isFirst}
          />
        )
      })}
    </Layout>
  )
}

function getPageSchema(site) {
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Paper Collective',
    alternateName: 'Papercollective',
    url: site.rootDomain,
    logo: buildSrc(site.organization?.logo, {
      width: 112,
      height: 112,
      format: 'jpg',
    }),
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: site.organization?.phone,
      contactType: 'customer service',
      contactOption: 'HearingImpairedSupported',
      areaServed: 'DK',
      availableLanguage: 'en',
    },
    sameAs: site.organization?.social?.map((s) => s.url),
  }
}

export async function getStaticProps({ preview, previewData }) {
  const pageData = await getStaticPage(
    `
    *[_type == "page" && _id == ${queries.homeID}] | order(_updatedAt desc)[0]{
      showIntro,
      modules[]{
        ${queries.modules(preview)}
      },
      title,
      seo
    }
  `,
    {
      active: preview,
      token: previewData?.token,
    }
  )

  return {
    props: {
      data: pageData,
    },
  }
}

export default Home
